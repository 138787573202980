<template>
  <div id="grecaptcha"></div>
</template>

<script setup>
  import { onMounted, defineEmits } from 'vue'

  const props = defineProps({
    sitekey: {
      //密钥
      type: String,
      required: true
    },
    size: {
      //大小
      type: String,
      default: 'normal'
    },
    theme: {
      //主题风格 dark
      type: String,
      default: 'light'
    },
    hl: {
      //语言
      type: String,
      default: 'zh'
    },
    delay: {
      //这个延迟是因为要等待引入的script文件生效
      type: Number,
      default: 200
    }
  })
  
  const $emits = defineEmits(['verify', 'expire', 'fail'])

  const expireCallback = function () {
    console.log('reCAPTCHA token expired. Please try again.');
    $emits('expire')
  }
  const failCallback = function (error) {
    console.error('reCAPTCHA Error:', error);
    $emits('fail')
  }
  const verifyCallback = function (res) {
    console.log(res, 'token')
    $emits('verify', res) //这个是返回的token，给后端去处理。
  }

  const onloadCallback = function () {
    setTimeout(() => {
      if (window.grecaptcha?.render) {
        window.grecaptcha.render('grecaptcha', {
          //根据reCaptcha的配置
          sitekey: props.sitekey,
          theme: props.theme,
          hl: props.hl,
          size: props.size,
          'expired-callback': expireCallback,
          'error-callback': failCallback,
          callback: verifyCallback
        })
      } else {
        failCallback()
      }
    }, props.delay)
  }

  onMounted(() => {
    onloadCallback()
  })
</script>

<style lang="less" scoped></style>
